.img {
  max-width: 15rem;
  height: 15rem;
  object-fit: cover;
}

.img:last-of-type {
  padding-left: 0;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 30rem;
}

.wrapper {
  position: absolute;
  display: flex;
}

.skillSection {
  display: flex;
  animation: swipe 20000ms linear infinite backwards;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}
